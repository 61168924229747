import logo from './logo.svg';
import './App.css';
import PeerVideoChat from './container';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <PeerVideoChat />
      </header>
    </div>
  );
}

export default App;
