import { useEffect } from "react";
import io from "socket.io-client";

const PeerVideoChat = () => {
  let arr = [];
  let userId;
  let localStream;
  let serverConnection; // This will be our socket.io connection
  let peerConnections = {}; // key is uuid, values are peer connection object and user defined display name string
  let count = 0;
  let callData;
  var audioEnabled = true;
  var videoEnabled = true;
  let peerConnectionConfig = {
    iceServers: [
      { urls: "stun:stun.stunprotocol.org:3478" },
      { urls: "stun:stun.l.google.com:19302" },
      {
        urls: "turn:52.55.196.27",
        username: "babble",
        credential: "babble",
      },
    ],
  };

  function start() {
    let urlParams = new URLSearchParams(window.location.search);
    userId = urlParams.get("displayName") || prompt("Enter your name", "");
    document
      .getElementById("localVideoContainer")
      .appendChild(makeLabel(userId));

    let constraints = {
      video: videoEnabled,
      audio: audioEnabled,
    };

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          localStream = stream;
          document.getElementById("localVideo").srcObject = stream;
        })
        .catch(errorHandler)

        .then(() => {
          serverConnection = io("https://dev-babble-call-api.primathontech.co.in/", {
            query: { userId: userId },
          });

          serverConnection.on("peerConnection", peerConnection);
          serverConnection.on("sdpTransfer", sdpTransfer);
          serverConnection.on("iceCandidate", iceCandidate);
          serverConnection.on("callDetail", (callDetail) => {
            console.log(callDetail, "call detail");
          });

          serverConnection.on("incomingCall", (callDetail) => {
            document.getElementById("callACcept").style.display = "block";
            console.log("incomingCall", callDetail);
            callData = callDetail;
          });
        })
        .catch(errorHandler);
    } else {
      alert("Your browser does not support getUserMedia API");
    }
  }

  function setUpPeer(peerUuid, displayName, initCall = false) {
    console.log(peerUuid, displayName, initCall, peerConnections);
    peerConnections[peerUuid] = {
      displayName,
      pc: new RTCPeerConnection(peerConnectionConfig),
    };
    peerConnections[peerUuid].pc.onicecandidate = (event) =>
      gotIceCandidate(event, peerUuid);
    peerConnections[peerUuid].pc.ontrack = (event) =>
      gotRemoteStream(event, peerUuid);
    peerConnections[peerUuid].pc.oniceconnectionstatechange = (event) =>
      checkPeerDisconnect(event, peerUuid);
    peerConnections[peerUuid].pc.addStream(localStream);

    if (initCall) {
      peerConnections[peerUuid].pc
        .createOffer()
        .then((description) => createdDescription(description, peerUuid))
        .catch(errorHandler);
    }
  }

  function peerConnection(message) {
    let signal = message;
    // callData = message;

    console.log(callData);

    // if (
    //   userId == signal.clientId ||
    //   (signal.receiverClientId != userId && signal.receiverClientId != "all")
    // )
    //   return;

    if (signal.displayName && signal.receiverClientId === "all") {
      setUpPeer(signal.clientId, signal.displayName);
      console.log("all");

      serverConnection.emit("peerConnection", {
        clientId: userId,
        displayName: userId,
        callerId: callData.callerDetail.id,
        receiverClientId: signal.clientId || "",
        roomId: callData.roomId,
      });
    } else if (signal.displayName && signal.receiverClientId === userId) {
      console.log("group");
      setUpPeer(signal.clientId, signal.displayName, true);
    }
  }

  function callUser() {
    let inputValue = document.getElementById("inputField").value;
    const receiverIds = inputValue.split(",");
    console.log(inputValue, receiverIds, "receiverIds");

    let callDetails = {
      clientId: userId, //3
      callerId: userId, //3,
      audioEnabled: true,
      videoEnabled: true,
      receiverClientId: "all",
      audioVideoCall: "video",
      receiverIds: receiverIds, //['4']
    };
    serverConnection.emit("callUser", callDetails, (acknowledgmentData) => {
      console.log("Acknowledgment received:", acknowledgmentData);

      callData = acknowledgmentData.data;
    });
  }

  function Accept() {
    serverConnection.emit("callStatus", {
      clientId: userId,
      roomId: callData.roomId,
      status: "callAccepted",
    });
    serverConnection.emit("peerConnection", {
      displayName: userId,
      clientId: userId,
      callerId: callData.callerDetail.id,
      receiverClientId: "all" || "",
      roomId: callData.roomId,
    });
  }

  function sdpTransfer(message) {
    let signal = message;
    let peerUuid = signal.clientId;

    if (
      userId == signal.clientId ||
      (signal.receiverClientId != userId && signal.receiverClientId != "all")
    ) {
      console.log(signal, "sdpTransfer");
      return;
    }

    console.log("sdp", peerConnections, message);

    peerConnections[peerUuid].pc
      .setRemoteDescription(new RTCSessionDescription(signal.sdp))
      .then(() => {
        if (signal.sdp.type == "offer") {
          peerConnections[peerUuid].pc
            .createAnswer()
            .then((description) => createdDescription(description, peerUuid))
            .catch(errorHandler);
        }
      })
      .catch(errorHandler);
  }

  function iceCandidate(message) {
    let signal = message;
    let peerUuid = signal.clientId;

    console.log("iceCandidate", message, peerConnections);

    peerConnections[peerUuid].pc
      .addIceCandidate(new RTCIceCandidate(signal.ice))
      .catch(errorHandler);
  }

  function gotIceCandidate(event, peerUuid) {
    if (event.candidate != null) {
      serverConnection.emit("iceCandidate", {
        ice: event.candidate,
        clientId: userId,
        callerId: callData.callerDetail.id,
        receiverClientId: peerUuid || "",
        roomId: callData.roomId,
      });
    }
  }

  function createdDescription(description, peerUuid) {
    peerConnections[peerUuid].pc
      .setLocalDescription(description)
      .then(() => {
        serverConnection.emit("sdpTransfer", {
          sdp: peerConnections[peerUuid].pc.localDescription,
          clientId: userId,
          callerId: callData.callerDetail.id,
          receiverClientId: peerUuid,
          roomId: callData.roomId,
        });
      })
      .catch(errorHandler);
  }
  // errorHandler function remains the same

  function gotRemoteStream(event, peerUuid) {
    console.log(`got remote stream, peer ${peerUuid}`);
    // assign stream to new HTML video element
    let vidElement = document.createElement("video");
    vidElement.setAttribute("autoplay", "");
    vidElement.setAttribute("muted", "");
    vidElement.srcObject = event.streams[0];

    let vidContainer = document.createElement("div");
    vidContainer.setAttribute("id", `remoteVideo_${peerUuid}`);

    arr.push(`remoteVideo_${peerUuid}`);
    if (arr[0] != arr[1]) {
      vidContainer.setAttribute("class", "videoContainer");
      vidContainer.appendChild(vidElement);
      vidContainer.appendChild(
        makeLabel(peerConnections[peerUuid].displayName)
      );

      document.getElementById("videos").appendChild(vidContainer);
      count++;
      updateLayout();
    } else if (arr[0] == arr[1]) {
      arr = [];
    }
  }

  function checkPeerDisconnect(event, peerUuid) {
    let state = peerConnections[peerUuid].pc.iceConnectionState;
    console.log(`connection with peer ${peerUuid} ${state}`);
    if (state === "failed" || state === "closed" || state === "disconnected") {
      delete peerConnections[peerUuid];
      document
        .getElementById("videos")
        .removeChild(document.getElementById(`remoteVideo_${peerUuid}`));
      updateLayout();
    }
  }

  function updateLayout() {
    // update CSS grid based on number of diplayed videos
    let rowHeight = "98vh";
    let colWidth = "98vw";

    let numVideos = Object.keys(peerConnections).length + 1; // add one to include local video

    if (numVideos > 1 && numVideos <= 4) {
      // 2x2 grid
      rowHeight = "48vh";
      colWidth = "48vw";
    } else if (numVideos > 4) {
      // 3x3 grid
      rowHeight = "32vh";
      colWidth = "32vw";
    }

    document.documentElement.style.setProperty(`--rowHeight`, rowHeight);
    document.documentElement.style.setProperty(`--colWidth`, colWidth);
  }

  function makeLabel(label) {
    let vidLabel = document.createElement("div");
    vidLabel.appendChild(document.createTextNode(label));
    vidLabel.setAttribute("class", "videoLabel");
    return vidLabel;
  }

  function toggleAudio() {
    console.log("audio");
    audioEnabled = !audioEnabled;
    var audioTracks = localStream.getAudioTracks();
    if (audioTracks.length > 0) {
      audioTracks[0].enabled = audioEnabled;
      console.log(callData);
      serverConnection.emit("mediaChange", {
        clientId: userId,
        audioEnabled: audioEnabled,
        roomId: callData.roomId,
      });
    }
  }

  // Function to toggle video
  function toggleVideo() {
    console.log("video");
    videoEnabled = !videoEnabled;
    var videoTracks = localStream.getVideoTracks();
    if (videoTracks.length > 0) {
      videoTracks[0].enabled = videoEnabled;
      console.log(videoEnabled);
      serverConnection.emit("mediaChange", {
        clientId: userId,
        videoEnabled: videoEnabled,
        roomId: callData.roomId,
      });
    }
  }

  function errorHandler(error) {
    console.log(error);
  }

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div id="videos" className="videos">
        <div id="localVideoContainer" className="videoContainer">
          <video id="localVideo" autoPlay muted />
        </div>
        <label htmlFor="inputField">
          Enter something:
          <input type="text" id="inputField" name="inputField" />
        </label>
        <br />
        <br />
        <button type="button" onClick={callUser}>
          Submit
        </button>
        <div id="controls">
          <button onClick={toggleAudio}>Toggle Audio</button>
          <button onClick={toggleVideo}>Toggle Video</button>
        </div>

        <div id="callACcept" style={{ display: "none" }}>
          <button type="button" onClick={Accept}>
            Accept Call
          </button>
        </div>
      </div>
    </div>
  );
};

export default PeerVideoChat;
